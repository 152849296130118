// `src/components/Card.tsx`
import * as React from 'react';
import Image from 'next/image';
import Button from "@/src/components/Button";
import useIntersectionObserver from "@/src/hooks/useIntersectionObserver";

interface CardProps {
    imageSrc: string;
    imageWidth?: number;
    imageHeight?: number;
    title: string;
    description: string;
    link?: string;
    titleElement: "p" | "h2" | "h3" | "h4" | "h5" | "h6";
    fontWeight: string;
    titleFontWeight: string;
    imagePosition?: string;
    padding?: string;
    showButton?: boolean;
    isLastCard?: boolean;
    objectPosition?: string;
    buttonText?: string;
}

const Card: React.FC<CardProps> = ({
                                       imageSrc,
                                       imageWidth,
                                       imageHeight,
                                       title,
                                       description,
                                       link,
                                       titleElement,
                                       titleFontWeight,
                                       fontWeight,
                                       imagePosition = 'left',
                                       padding,
                                       showButton,
                                       isLastCard,
                                        objectPosition ='object-cover',
                                       buttonText= 'See EKG Use Cases'
                                   }) => {
    const {
        ref: cardRef,
        isIntersecting: cardVisible,
        hasIntersected
    } = useIntersectionObserver<HTMLDivElement>({threshold: 0.1});
    const TitleTag = titleElement;
    const [src, setSrc] = React.useState(imageSrc);

    return (
        <div ref={cardRef}
             className={`bg-transparent flex flex-col items-center border-b border-gray-300 transition-opacity duration-1000 ${hasIntersected ? 'opacity-100' : 'opacity-0'}`}>
            <div
                className={`flex flex-col lg:flex-row items-center w-full ${imagePosition === 'right' ? 'lg:flex-row-reverse' : ''}`}>
                <Image src={imageSrc} alt={title} loading="lazy" width={imageWidth} height={imageHeight}
                       className={`${objectPosition} w-full lg:w-2/5 h-auto`} onError={() => setSrc(imageSrc)}/>
                <div className={`w-full lg:w-3/5 pl-0 ${padding} flex flex-col justify-center mt-4 lg:mt-0`}>
                    <TitleTag className={`my-0 text-navbarBackground ${titleFontWeight}`}>{title}</TitleTag>
                    <p className={`sqsrte-large leading-6 pb-4 text-black ${fontWeight}`}>{description}</p>
                    <div>
                        {showButton && link && (
                            <Button
                                href={link}
                                text={buttonText}
                                bgColor="bg-navbarBackground"
                                textColor="text-foreground"
                                ariaLabel={`Read more about ${title}`}
                            />
                        )}
                    </div>
                </div>
            </div>
            {!isLastCard && <hr className="w-full border-t border-gray-300 my-8"/>}
        </div>
    );
};

export default Card;