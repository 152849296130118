// `src/app/(marketing)/sectionOurServices.tsx`
'use client';
import * as React from 'react';
import SectionWithCards from '@/src/components/Sections/SectionWithCards';

const iconsValues = [
    {
        imageSrc: './image/cards/service-summaryArtboard+19.png',
        title: 'Plan',
        description: 'Translate your vision, goals and needs into the right use cases. Align all parties and define key outcomes. Assess where you are to inform your roadmap.'
    },
    {
        imageSrc: './image/cards/service-summaryArtboard+21.png',
        title: 'Build',
        description: 'Architect, design, deliver and manage the implementation. Advise on platform and tools. Onboard, map and model your data. '
    },
    {
        imageSrc: './image/cards/service-summaryArtboard+22.png',
        title: 'Run',
        description: 'Service and support for your EKG platform. Improve the performance and resilience.'
    }
];

const SectionOurServices = () => {
    return (
        <SectionWithCards
            title="Our Services"
            paragraphs={[
                { text: "We support our clients from strategic evaluation through to EKG implementation and operation. Our consulting services are based on structured methodology and pragmatic delivery experience." }
            ]}
            buttonProps={{
                href: "/services/",
                text: "our EKG Services",
                bgColor: "bg-darkest",
                textColor: "text-black dark:text-white"
            }}
            cards={iconsValues}
            backgroundImage="bg-services-pattern"
            overlayClass="bg-black/50"
            titleColor="text-foreground dark:text-white"
            paragraphColor="text-foreground dark:text-white"
            hasOverlay={true}
        />
    );
};

export default SectionOurServices;