// `src/app/(marketing)/sectionAboutUs.tsx`
'use client';
import * as React from 'react';
import Section from '@/src/components/Sections/Section';

const SectionAboutUs = () => {
    return (
        <Section
            title="About Us"
            paragraphs={[
                {text: "We are an international partnership of knowledge graph architects, methodology specialists, software engineers, and data management experts. We understand the challenges of managing data in complex and interconnected environments. We deliver enterprise knowledge graph platforms that connect business silos and drive mission-critical business applications."}
            ]}
            height={'min-h-[700px]'}
            buttonProps={{
                href: "/about/",
                text: "Learn more about us",
                bgColor: "bg-darkest",
                textColor: "text-black",
                padding: "px-6 py-2"
            }}
            backgroundImage="bg-bubbles1-pattern"
            overlayClass="bg-white/60"
            titleColor="text-navbarBackground"
            paragraphColor="text-black"
        />
    );
};

export default SectionAboutUs;