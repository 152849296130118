// `src/components/SectionWithCards.tsx`
'use client';
import * as React from 'react';
import Button from '@/src/components/Button';
import useIntersectionObserver from '@/src/hooks/useIntersectionObserver';
import CardIcon from '@/src/components/Cards/CardIcon';

interface Paragraph {
    text: string;
    isStrong?: boolean;
}

interface Card {
    imageSrc: string;
    title: string;
    description: string;
    hasSpan?: boolean;
    spanText?: string;
    linkId?: string;
    id?: string;
    borderColor?: string;
    titleElement?: string;
    hasLink?: boolean;
    linkText?: string;
    padding?: string;
}

interface SectionWithCardsProps {
    title: string;
    paragraphs: Paragraph[];
    buttonProps?: {
        href: string;
        text: string;
        bgColor: string;
        textColor: string;
        padding?: string;
    };
    cards: Card[];
    backgroundImage?: string;
    backgroundColor?: string;
    height?: string;
    overlayClass?: string;
    titleColor?: string;
    paragraphColor?: string;
    containerClass?: string;
    hasOverlay?: boolean;
    showButton?: boolean;
    defaultBorderColor?: string;
    cardPadding?: string;
}

const SectionWithCards: React.FC<SectionWithCardsProps> = ({
                                                               title,
                                                               paragraphs,
                                                               buttonProps,
                                                               cards,
                                                               backgroundImage,
                                                               backgroundColor = '',
                                                               height = 'min-h-max lg:min-h-[900px]',
                                                               overlayClass = 'bg-white/60',
                                                               titleColor = 'text-navbarBackground',
                                                               paragraphColor = 'text-black',
                                                               containerClass = 'md:py-0',
                                                               hasOverlay = true,
                                                               showButton = true,
                                                               defaultBorderColor = 'border-navbarBackground',
    cardPadding
                                                           }) => {
    const {ref: sectionRef, isIntersecting: sectionVisible, hasIntersected} = useIntersectionObserver({threshold: 0.1});

    return (
        <section
            ref={sectionRef}
            className={`${height} flex items-center justify-center relative ${backgroundImage ? `bg-cover bg-center bg-no-repeat ${backgroundImage}` : backgroundColor}`}
        >
            {hasOverlay &&
                <div className={`absolute inset-0 backdrop-opacity-20 backdrop-invert ${overlayClass}`}></div>}
            <div className={`container h-full flex items-center justify-center px-6 py-14 ${containerClass}`}>
                <div className="px-4 z-10 grid grid-cols-1 md:grid-cols-12 md:gap-4">
                    <div className="md:col-span-5">
                        <h2 className={`${titleColor} whitespace-pre-wrap md:transition-opacity md:duration-1000 ${hasIntersected ? 'md:opacity-100' : 'md:opacity-0'}`}>
                            {title}
                        </h2>
                        <div className="pb-12 lg:pb-0 lg:mt-8 lg:mb-16">
                            <div className="sqsrte-large leading-7 sm:py-4 whitespace-pre-wrap">
                                {paragraphs.map((paragraph, index) => (
                                    <p key={index}
                                       className={`${paragraphColor} md:transition-opacity md:duration-1000 ${hasIntersected ? 'md:opacity-100' : 'md:opacity-0'}`}>
                                        {paragraph.isStrong ? <strong>{paragraph.text}</strong> : paragraph.text}
                                    </p>
                                ))}
                            </div>
                        </div>
                        {showButton && buttonProps &&
                            <Button
                                href={buttonProps.href}
                                text={buttonProps.text}
                                bgColor={buttonProps.bgColor}
                                textColor={buttonProps.textColor}
                                padding={buttonProps.padding}
                            />
                        }
                    </div>
                    <div className="py-16 lg:py-8 md:col-start-7 md:col-end-12 grid grid-cols-1 gap-4">
                        {cards.map((card, index) => (
                            <CardIcon
                                key={index}
                                id={card.id}
                                imageSrc={card.imageSrc}
                                title={card.title}
                                description={card.description}
                                hasSpan={card.hasSpan}
                                spanText={card.spanText}
                                isLastCard={index === cards.length - 1}
                                borderColor={card.borderColor || defaultBorderColor}
                                linkId={card.linkId}
                                hasLink={card.hasLink}
                                linkText={card.linkText}
                                padding={cardPadding}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionWithCards;