// `src/hooks/useIntersectionObserver.ts`
"use client";

import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = <T extends HTMLElement>(options: IntersectionObserverInit) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [hasIntersected, setHasIntersected] = useState(false);
    const ref = useRef<T | null>(null);

    useEffect(() => {
        const currentRef = ref.current;
        if (!currentRef) return;

        const observer = new IntersectionObserver(([entry]) => {
            // console.log('IntersectionObserver entry:', entry);
            setIsIntersecting(entry.isIntersecting);
            if (entry.isIntersecting) {
                setHasIntersected(true);
            }
        }, options);

        observer.observe(currentRef);

        return () => {
            observer.unobserve(currentRef);
        };
    }, [options]);

    return { ref, isIntersecting, hasIntersected };
};

export default useIntersectionObserver;
