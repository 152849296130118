// `src/components/Hero.tsx`
'use client';
import * as React from 'react';

interface HeroProps {
    title: string;
    words: string[];
    paragraph: string;
    backgroundImage?: string;
    height?: string;
}

const Hero: React.FC<HeroProps> = ({ title, words, paragraph, backgroundImage = 'bg-hero-pattern', height = 'min-h-screen' }) => {
    const [isMobile, setIsMobile] = React.useState(false);
    const [currentWordIndex, setCurrentWordIndex] = React.useState(0);
    const [isScrolledPast, setIsScrolledPast] = React.useState(false);
    const heroRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            setIsMobile(window.innerWidth <= 820);
        };

        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [words.length]);

    React.useEffect(() => {
        const handleScroll = () => {
            if (heroRef.current) {
                const heroHeight = heroRef.current.offsetHeight;
                const scrollPosition = window.scrollY;
                setIsScrolledPast(scrollPosition > heroHeight * 0.5);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            ref={heroRef}
            className={`relative ${height} flex items-center justify-center bg-cover bg-center bg-no-repeat ${backgroundImage} -mt-20 xl:-mt-10`}>
            <div className="absolute inset-0 backdrop-opacity-5 backdrop-invert bg-black/10"></div>
            <div className="container z-10">
                <div className="flex items-center justify-between hero-content text-center px-6">
                    <div className="p-4 py-8 lg:py-20 2xl:p-0 mx-auto">
                        <h1 className="text-left font-medium [text-wrap:balance] bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 to-50% to-slate-200 w-full">
                            <div className="flex flex-col items-start text-white leading-none -space-y-4 lg:-space-y-12">
                                <span>{title}</span>
                                <span className="inline-flex flex-col h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight)+0.35rem)] md:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight)+0.35rem)] overflow-hidden">
                                    <ul className="p-0 list-none block text-left leading-tight w-full items-center justify-center">
                                        {words.map((word, index) => (
                                            <li key={index} className={`absolute ${index === currentWordIndex ? 'animate-rotate-word' : 'hidden'}`}>
                                                {word}
                                            </li>
                                        ))}
                                    </ul>
                                </span>
                            </div>
                        </h1>
                        <div className="pt-32 lg:pt-8 grid lg:grid-cols-6">
                            <p className="sqsrte-large leading-7 lg:col-span-4 xl:pt-12 text-white text-left tracking-normal">
                                {paragraph}
                            </p>
                        </div>
                    </div>
                    <div className={`absolute bottom-10 mx-auto left-1/2 transform -translate-x-1/2 ${isScrolledPast ? 'hidden' : ''}`}>
                        {isMobile ? (
                            <div className="hidden"></div>
                        ) : (
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="4" stroke="currentColor" className="animate-scrollhint h-12 w-12 text-arrowHome z-50">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;