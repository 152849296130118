// `src/app/(marketing)/primaryMessage.tsx`
'use client';
import * as React from 'react';
import Section from '@/src/components/Sections/Section';

const PrimaryMessage = () => {
    return (
        <Section
            title="What Is An Enterprise Knowledge Graph?"
            paragraphs={[
                { text: "An enterprise knowledge graph (EKG) connects information about customers, products, markets and resources in new and meaningful ways, providing the next-generation data platform for fully digitalized user experiences." },
                { text: "Ask a question, get an answer. No matter where the data resides.", isStrong: true }
            ]}
            buttonProps={{
                href: "/ekg/",
                text: "Learn more about EKG",
                bgColor: "bg-white",
                textColor: "text-black",
                padding: "px-6 py-2"
            }}
            backgroundImage="bg-globe-pattern"
            overlayClass="bg-black/50"
            titleColor="text-foreground"
            paragraphColor="text-foreground"
        />
    );
};

export default PrimaryMessage;