// `src/app/(marketing)/makeTheLeap.tsx`
'use client';
import * as React from 'react';
import Section from '@/src/components/Sections/Section';

const MakeTheLeap = () => {
    return (
        <Section
            title="Make The Leap"
            paragraphs={[
                { text: "To take your first step towards a more meaningful data infrastructure, have a conversation with one of our experts." }
            ]}
            buttonProps={{
                href: "/contact/",
                text: "Book a Call",
                bgColor: "bg-white",
                textColor: "text-purple",
            }}
            backgroundColor="bg-navbarBackground"
            overlayClass=""
            titleColor="text-white"
            paragraphColor="text-foreground"
            containerClass="py-14 px-6 md:px-20"
            height="h-2/5"
            hasOverlay={false}
        />
    );
};

export default MakeTheLeap;