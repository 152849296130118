// `src/components/Section.tsx`
'use client';
import * as React from 'react';
import Button from '@/src/components/Button';
import useIntersectionObserver from '@/src/hooks/useIntersectionObserver';

interface Paragraph {
    text: string;
    isStrong?: boolean;
}

interface SectionProps {
    title: string;
    paragraphs: Paragraph[];
    buttonProps?: {
        href: string;
        text: string;
        bgColor: string;
        textColor: string;
        padding?: string;
    };
    backgroundImage?: string;
    backgroundColor?: string;
    height?: string;
    overlayClass?: string;
    titleColor?: string;
    paragraphColor?: string;
    containerClass?: string;
    hasOverlay?: boolean;
    showButton?: boolean;
}

const Section: React.FC<SectionProps> = ({
                                             title,
                                             paragraphs,
                                             buttonProps,
                                             backgroundImage,
                                             backgroundColor = '',
                                             height = 'min-h-max lg:min-h-[900px]',
                                             overlayClass = 'bg-white/60',
                                             titleColor,
                                             paragraphColor,
                                             containerClass = 'py-14 px-6 md:px-20 md:py-0',
                                             hasOverlay = true,
                                             showButton = true
                                         }) => {
    const {ref: sectionRef, isIntersecting: hasIntersected} = useIntersectionObserver({threshold: 0.1});

    return (
        <section
            ref={sectionRef}
            className={`${height} flex items-center justify-center relative ${backgroundImage ? `bg-cover bg-center bg-no-repeat ${backgroundImage}` : backgroundColor} transition-opacity duration-1000`}
        >
            {hasOverlay &&
                <div className={`absolute inset-0 backdrop-opacity-20 backdrop-invert ${overlayClass}`}></div>}
            <div className={`container h-full flex items-center justify-center py-14 px-6 ${containerClass}`}>
                <div className="p-4 z-10 grid grid-cols-1 md:grid-cols-12 md:gap-4">
                    <div className="md:col-span-5">
                        <h2 className={`${titleColor} whitespace-pre-wrap transition-opacity duration-1000 ${hasIntersected ? 'opacity-100' : 'opacity-0'}`}>
                            {title}
                        </h2>
                    </div>
                    <div className="md:col-start-7 md:col-end-12">
                        <div className="lg:mb-16">
                            <div className="sqsrte-large leading-7 sm:py-4 whitespace-pre-wrap">
                                {paragraphs.map((paragraph, index) => (
                                    <p key={index}
                                       className={`${paragraphColor} transition-opacity duration-1000 ${hasIntersected ? 'opacity-100' : 'opacity-0'}`}>
                                        {paragraph.isStrong ? <strong>{paragraph.text}</strong> : paragraph.text}
                                    </p>
                                ))}
                            </div>
                        </div>
                        {showButton && buttonProps && (
                            <Button
                                href={buttonProps.href}
                                text={buttonProps.text}
                                bgColor={buttonProps.bgColor}
                                textColor={buttonProps.textColor}
                                padding={buttonProps.padding}
                            />)}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section;