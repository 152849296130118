// `src/components/CardIcon.tsx`
import * as React from 'react';
import Image from 'next/image';
import useIntersectionObserver from "@/src/hooks/useIntersectionObserver";
import {FaArrowLeft} from "react-icons/fa";

interface CardProps {
    imageSrc: string;
    title: string;
    description: string;
    isLastCard?: boolean;
    hasSpan?: boolean;
    spanText?: string;
    linkId?: string;
    id?: string;
    borderColor?: string;
    hasLink?: boolean;
    linkText?: string;
    padding?: string;
}

const CardIcon: React.FC<CardProps> = ({
                                           imageSrc,
                                           title,
                                           description,
                                           isLastCard,
                                           hasSpan = "false",
                                           spanText,
                                           linkId,
                                           id,
                                           borderColor,
                                           hasLink = false,
                                           linkText,
                                           padding
                                       }) => {
    const {
        ref: cardRef,
        hasIntersected
    } = useIntersectionObserver<HTMLDivElement>({threshold: 0.1});
    const [src, setSrc] = React.useState(imageSrc);
    return (
        <div ref={cardRef} id={id}
             className={`pt-8 bg-transparent flex flex-col items-left border-b border-gray-300 transition-opacity duration-1000 ${hasIntersected ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`${padding} flex flex-row items-left w-full`}>
                <Image src={imageSrc} alt={title} loading="lazy" width={52} height={52} className="object-cover"
                       onError={() => setSrc(imageSrc)}/>
                <div className="w-full pl-4 flex flex-col justify-center mt-0 mb-2">
                    <h3  className="my-0 text-foreground font-semibold">{title}</h3>
                    <p className="sqsrte-large leading-7 pb-4 text-foreground font-normal">{description}</p>
                    {hasSpan &&
                        <span className="sqsrte-large leading-7 pb-8 text-darkest font-normal">{spanText}</span>}
                    {hasLink && <a href={linkId}
                                   className="flex items-center justify-evenly border-solid border border-white/50 rounded-full w-fit text-gray-50 font-light no-underline p-2 "><FaArrowLeft
                        className="mr-2"/>{linkText}</a>}
                </div>
            </div>
            {!isLastCard && <hr className={`w-full border-t ${borderColor} mb-8`}/>}
        </div>
    );
};

export default CardIcon;