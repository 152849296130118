// `src/app/(marketing)/sectionEkgValue.tsx`
'use client';
import * as React from 'react';
import SectionWithCards from '@/src/components/Sections/SectionWithCards';

const iconsValues = [
    {
        imageSrc: './image/cards/homeArtboard+19.png',
        title: 'Cost',
        description: 'Simplify your complex environment, leverage data for reuse and reduce the friction caused by integration.'
    },
    {
        imageSrc: './image/cards/homeArtboard+21.png',
        title: 'Control',
        description: 'Manage dependencies, risk and comply with regulatory obligations.'
    },
    {
        imageSrc: './image/cards/homeArtboard+22.png',
        title: 'Capability',
        description: 'Solve your toughest data challenges and open up new possibilities.'
    }
];

const SectionEkgValue = () => {
    return (
        <SectionWithCards
            title="The EKG Value Proposition"
            paragraphs={[
                { text: "EKG is whole new way of connecting businesses, data silos and streamlining operational processes." },
                { text: "It can fully connect all your data no matter where it resides and however it is stored. EKG delivers data that is precisely defined, machine-readable, uniquely identified and is based on standards that built the internet." }
            ]}
            buttonProps={{
                href: "/ekg/",
                text: "Learn more about EKG",
                bgColor: "bg-darkest",
                textColor: "text-black dark:text-white",
                padding: "px-6 py-2"
            }}
            cards={iconsValues}
            backgroundColor="bg-darkAccent"
            titleColor="text-foreground dark:text-white"
            paragraphColor="text-foreground dark:text-white"
            hasOverlay={false}
        />
    );
};

export default SectionEkgValue;